<template>
    <div>
        <router-link
            :to="{
                name: 'animalactivitychart',
                params: {
                    id: tracker.id,
                },
            }"
        >
            <ListItem class="l-center">
                <div class="l-inline l-gap-2 l-center">
                    <ActivityIcon width="24" height="24" />

                    <div class="l-stack">
                        <div class="l-inline l-center-v">
                            {{ $t('showActivity') }}
                        </div>
                    </div>
                </div>
                <p>
                    <BarChartIcon :width="20" :height="20" color="lightgray" />
                </p>
            </ListItem>
        </router-link>

        <measurement-item
            v-if="isMeasurementAvailable('battery')"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'battery' },
            }"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>
            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="
                !isMeasurementAvailable('battery') &&
                    isMeasurementAvailable('battery_voltage')
            "
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'battery_voltage' },
            }"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="100"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BatteryIcon from '../../icons/BatteryIcon'
import ActivityIcon from '../../icons/ActivityIcon'
import BarChartIcon from '../../icons/BarChartIcon'
import MeasurementItem from '../../MeasurementItem'
import ListItem from '../../ListItem'

export default {
    name: 'AnimalMeasurement',
    components: {
        BatteryIcon,
        MeasurementItem,
        BarChartIcon,
        ListItem,
        ActivityIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        battery() {
            const measurement = 'battery'

            return {
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
            }
        },
        batteryVoltage() {
            const measurement = 'battery_voltage'

            return {
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
    },
    methods: {
        isMeasurementAvailable(measurement) {
            return !!this.tracker.asset_details.sensor_data[measurement]
        },
        getItemTitle(measurement) {
            const lastUpdate = this.tracker.asset_details.sensor_data[
                measurement
            ].last_update

            return lastUpdate
                ? `${this.$t('lastMeasurement')}: ${moment(lastUpdate).format(
                      'DD.MM.YYYY HH:mm'
                  )}`
                : null
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "lastMeasurement": "Last measurement",
        "showActivity": "Show activity"
    },
    "de": {
        "battery": "Batterie",
        "lastMeasurement": "Letzte Messung",
        "showActivity": "Aktivität anzeigen"
    },
    "it": {
        "battery": "Batteria",
        "lastMeasurement": "Ultimo misurazione",
        "showActivity": "Mostra attività"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
