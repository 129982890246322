<template>
    <div>
        <measurement-item
            v-if="fillLevel"
            :title="fillLevel.title"
            :datetime="fillLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: fillLevel.key },
            }"
        >
            <template #icon>
                <bin-fill-icon
                    width="24"
                    height="24"
                    :fullness="fillLevel.value"
                    :fill-color="fillLevel.value >= 20 ? '#41b883' : '#d32f2f'"
                />
            </template>

            <template #content>
                {{ $t('fillLevel') }}:

                <h3 class="sensor-data-percentage">
                    {{ fillLevel.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="mass"
            :title="mass.title"
            :datetime="mass.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: mass.key },
            }"
        >
            <template #icon>
                <scales-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('mass') }}:

                <h3>{{ mass.value }} t</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="volume"
            :title="volume.title"
            :datetime="volume.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: volume.key },
            }"
        >
            <template #icon>
                <cylinder-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('volume') }}:

                <h3>{{ volume.value }} m³</h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BinFillIcon from './icons/BinFillIcon'
import CylinderIcon from './icons/CylinderIcon'
import MeasurementItem from './MeasurementItem'
import ScalesIcon from './icons/ScalesIcon'

export default {
    name: 'SiloMeasurement',
    components: {
        BinFillIcon,
        CylinderIcon,
        MeasurementItem,
        ScalesIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        fillLevel() {
            const data = this.getMeasurementItem('fill_level')
            if (data) {
                data.value = Math.round(data.value * 100)
            }
            return data
        },
        mass() {
            const data = this.getMeasurementItem('mass')
            if (data) {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        volume() {
            return this.getMeasurementItem('volume')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fillLevel": "Fill level",
        "lastMeasurement": "Last measurement",
        "mass": "Mass",
        "volume": "Volume"
    },
    "de": {
        "fillLevel": "Füllstand",
        "lastMeasurement": "Letzte Messung",
        "mass": "Masse",
        "volume": "Volumen"
    },
    "it": {
        "fillLevel": "Livello di riempimento",
        "lastMeasurement": "Ultimo misurazione",
        "mass": "Massa",
        "volume": "Volume"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
