var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.waterLevel)?_c('measurement-item',{attrs:{"title":_vm.waterLevel.title,"datetime":_vm.waterLevel.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.waterLevel.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('bar-chart-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('waterLevel'))+": "),_c('h3',{staticClass:"sensor-data-distance-mm"},[_vm._v(" "+_vm._s(_vm.waterLevel.value)+" ")])]},proxy:true}],null,false,1836486947)}):_vm._e(),(_vm.potential)?_c('measurement-item',{attrs:{"title":_vm.potential.title,"datetime":_vm.potential.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.potential.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('line-chart-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('potential'))+": "),_c('h3',[_vm._v(_vm._s(_vm.potential.value))])]},proxy:true}],null,false,2107957874)}):_vm._e(),(_vm.isAdminOrStaff && _vm.tau)?_c('measurement-item',{attrs:{"title":_vm.tau.title,"datetime":_vm.tau.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.tau.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('line-chart-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('tau'))+": "),_c('h3',[_vm._v(_vm._s(_vm.tau.value))])]},proxy:true}],null,false,3600513522)}):_vm._e(),(_vm.humidity)?_c('measurement-item',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":_vm.temperature
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                          dataTypes: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.humidity.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('drop-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,2827658156)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":_vm.humidity
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                          dataTypes: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.temperature.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('thermometer-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,1823401043)}):_vm._e(),(_vm.battery)?_c('measurement-item',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.battery.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('battery-icon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.battery.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,3710752572)}):_vm._e(),(!_vm.battery && _vm.batteryVoltage)?_c('measurement-item',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.batteryVoltage.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('battery-icon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,4255582301)}):_vm._e(),(_vm.probeState)?_c('measurement-item',{attrs:{"title":_vm.probeState.title,"datetime":_vm.probeState.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('filled-circle-icon',{class:_vm.probeState.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('probeState'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.$t(_vm.probeState.value ? 'statusOk' : 'statusNotOk'))+" ")])]},proxy:true}],null,false,3750513344)}):_vm._e(),(_vm.isAdminOrStaff && _vm.upperLoop)?_c('measurement-item',{attrs:{"title":_vm.upperLoop.title,"datetime":_vm.upperLoop.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.upperLoop.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('filled-circle-icon',{class:_vm.upperLoop.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('upperLoop'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.$t(_vm.upperLoop.value ? 'loopPositive' : 'loopNegative'))+" ")])]},proxy:true}],null,false,2088261470)}):_vm._e(),(_vm.isAdminOrStaff && _vm.lowerLoop)?_c('measurement-item',{attrs:{"title":_vm.lowerLoop.title,"datetime":_vm.lowerLoop.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.lowerLoop.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('filled-circle-icon',{class:_vm.lowerLoop.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lowerLoop'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.$t(_vm.lowerLoop.value ? 'loopPositive' : 'loopNegative'))+" ")])]},proxy:true}],null,false,4152372671)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }