var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fillLevel)?_c('measurement-item',{attrs:{"title":_vm.fillLevel.title,"datetime":_vm.fillLevel.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.fillLevel.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('bin-fill-icon',{attrs:{"width":"24","height":"24","fullness":_vm.fillLevel.value,"fill-color":_vm.fillLevel.value >= 20 ? '#41b883' : '#d32f2f'}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fillLevel'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.fillLevel.value)+" ")])]},proxy:true}],null,false,1463718370)}):_vm._e(),(_vm.mass)?_c('measurement-item',{attrs:{"title":_vm.mass.title,"datetime":_vm.mass.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.mass.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('scales-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('mass'))+": "),_c('h3',[_vm._v(_vm._s(_vm.mass.value)+" t")])]},proxy:true}],null,false,2098047337)}):_vm._e(),(_vm.volume)?_c('measurement-item',{attrs:{"title":_vm.volume.title,"datetime":_vm.volume.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.volume.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('cylinder-icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('volume'))+": "),_c('h3',[_vm._v(_vm._s(_vm.volume.value)+" m³")])]},proxy:true}],null,false,4171891914)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }