<template>
    <div>
        <measurement-item
            v-if="waterLevel"
            :title="waterLevel.title"
            :datetime="waterLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: waterLevel.key },
            }"
        >
            <template #icon>
                <bar-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('waterLevel') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ waterLevel.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: humidity.key,
                          },
                      }
            "
        >
            <template #icon>
                <drop-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: temperature.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <battery-icon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <battery-icon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BarChartIcon from './icons/BarChartIcon'
import BatteryIcon from './icons/BatteryIcon'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'FlatRoofMeasurement',
    components: {
        BarChartIcon,
        BatteryIcon,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        waterLevel() {
            return this.getMeasurementItem('water_level')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "humidity": "Humidity",
        "lastMeasurement": "Last measurement",
        "temperature": "Temperature",
        "waterLevel": "Water level"
    },
    "de": {
        "battery": "Batterie",
        "humidity": "Luftfeuchtigkeit",
        "lastMeasurement": "Letzte Messung",
        "temperature": "Temperatur",
        "waterLevel": "Wasserpegel"
    },
    "it": {
        "battery": "Batteria",
        "humidity": "Humidity",
        "lastMeasurement": "Ultimo misurazione",
        "temperature": "Temperatura",
        "waterLevel": "Livello dell'acqua"
    }
}
</i18n>
