var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.entry)?_c('div',{staticClass:"l-padded"},[_c('p',[_vm._v(_vm._s(_vm.$t('assetDoesNotExist')))])]):[_c('div',{staticClass:"u-bb"},[_c('AssetDetailOverview',{attrs:{"tracker":_vm.entryDetailed || _vm.entry,"show-edit-button":true}})],1),(
                _vm.entry.location_details ||
                    (_vm.entry.asset_details &&
                        _vm.entry.asset_details.position.latitude &&
                        _vm.entry.asset_details.position.longitude)
            )?_c('div',{staticClass:"u-bb"},[_c('div',{staticClass:"l-padded u-bb"},[_vm._v(_vm._s(_vm.$t('positionTitle')))]),(_vm.entry.location_details)?_c('router-link',{attrs:{"to":{
                    name: 'location',
                    params: { id: _vm.entry.location_details.id },
                }}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2"},[_c('pin-location-icon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack l-gap-1 l-center"},[_c('h3',[_vm._v(" "+_vm._s(_vm.entry.location_details && _vm.entry.location_details.name)+" ")])])],1),_c('p',[_c('i',{staticClass:"arrow right"})])])],1):_vm._e(),(_vm.showGoogleMapsLink)?_c('a',{staticClass:"t-underline",attrs:{"href":'http://www.google.com/maps/place/' +
                        _vm.entry.asset_details.position.latitude +
                        ',' +
                        _vm.entry.asset_details.position.longitude,"target":"_blank"}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2 l-center"},[_c('open-in-new-icon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack l-gap-1"},[_c('div',[_c('a',{staticClass:"t-underline",attrs:{"href":'http://www.google.com/maps/place/' +
                                            _vm.entry.asset_details.position
                                                .latitude +
                                            ',' +
                                            _vm.entry.asset_details.position
                                                .longitude,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('showOnMap'))+" ")])])])],1)])],1):_vm._e(),(
                    _vm.entry.last_gps_measurement &&
                        _vm.entry.last_gps_measurement.lat &&
                        _vm.entry.last_gps_measurement.lng
                )?_c('router-link',{attrs:{"to":{ name: 'locationhistory', params: { id: _vm.id } }}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2"},[_c('clock-icon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack l-gap-1 l-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('history')))])])],1),_c('p',[_c('i',{staticClass:"arrow right"})])])],1):_vm._e(),(!_vm.entry.asset_details.static)?_c('ListItem',{staticClass:"l-inline l-spread l-center"},[_c('div',[_vm._v(_vm._s(_vm.$t('followTrackerUpdates')))]),_c('toggle-button',{attrs:{"value":_vm.shouldFollowActiveTrackerUpdates,"sync":true,"color":"#02a5e1","width":40,"height":24,"speed":150},on:{"input":_vm.setShouldFollowActiveTrackerUpdates}})],1):_vm._e()],1):_vm._e(),(_vm.isMeasurementsAvailable)?_c('div',{staticClass:"u-bb"},[_c('div',{staticClass:"l-padded u-bb"},[_vm._v(_vm._s(_vm.$t('measurements')))]),_c('MeasurementsList',{attrs:{"tracker":_vm.entry}})],1):_vm._e(),_c('downlinks-list',{staticClass:"u-bb",attrs:{"id":_vm.id}}),(_vm.hasRulesAccess)?_c('asset-notifications',{staticClass:"u-bb",attrs:{"asset":_vm.entry.asset}}):_vm._e(),(_vm.config)?_c('div',{staticClass:"u-bb"},[_c('div',{staticClass:"l-padded u-bb"},[_vm._v(_vm._s(_vm.$t('configuration')))]),_c('asset-config-list',{attrs:{"config":_vm.config}})],1):_vm._e(),_c('asset-documents',{staticClass:"u-bb",attrs:{"asset":_vm.entry.asset,"show-edit-actions":_vm.userHasEditRights}}),(_vm.isSuperuser)?_c('div',{staticClass:"u-bb"},[_c('div',{staticClass:"l-padded u-bb"},[_vm._v(_vm._s(_vm.$t('diagnostics')))]),_c('router-link',{attrs:{"to":{
                    name: 'assetConnectionHeatmap',
                    params: { assetId: _vm.id },
                }}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2"},[_c('heatmap-icon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack l-gap-1 l-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('connectionHeatmap')))])])],1),_c('p',[_c('i',{staticClass:"arrow right"})])])],1),_c('router-link',{attrs:{"to":{
                    name: 'networkChart',
                    params: { assetId: _vm.id },
                }}},[_c('ListItem',[_c('div',{staticClass:"l-inline l-gap-2"},[_c('network-signal-icon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack l-gap-1 l-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t('signalQuality')))])])],1),_c('p',[_c('i',{staticClass:"arrow right"})])])],1)],1):_vm._e()],_c('transition',{attrs:{"name":"slide-from-right"}},[(_vm.isChartViewActive)?_c('div',{staticClass:"detached-panel"},[_c('div',{staticClass:"l-inline l-center-v l-spread l-padded u-bb detached-panel-header"},[_vm._v(" "+_vm._s(_vm.detachedPanelTitle)+" "),_c('router-link',{attrs:{"to":"."}},[_c('icon-button',{on:{"click":function($event){return _vm.$emit('expand')}}},[_c('remove-icon',{attrs:{"width":"24","height":"24","color":"black"}})],1)],1)],1),_c('div',{staticClass:"detached-panel-body"},[_c('router-view')],1)]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }