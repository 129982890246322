<template>
    <div>
        <measurement-item v-if="interval">
            <template #icon>
                <cog-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('interval') }}:

                <h3>{{ interval }}</h3>
            </template>
        </measurement-item>

        <template v-if="isAdminOrStaff">
            <measurement-item v-if="config.probe_address">
                <template #icon>
                    <cog-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('probeAddress') }}:

                    <h3>{{ config.probe_address }}</h3>
                </template>
            </measurement-item>
        </template>

        <template v-if="isStaff">
            <measurement-item v-if="config.gps_accuracy_goal">
                <template #icon>
                    <cog-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('gpsAccuracyGoal') }}:

                    <h3 class="sensor-data-distance-cm">
                        {{ config.gps_accuracy_goal }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item v-if="config.gps_fix_interval">
                <template #icon>
                    <cog-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('gpsFixInterval') }}:

                    <h3 class="sensor-data-time-s">
                        {{ config.gps_fix_interval }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item v-if="config.gps_fix_time">
                <template #icon>
                    <cog-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('gpsFixTime') }}:

                    <h3 class="sensor-data-time-s">
                        {{ config.gps_fix_time }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item v-if="config.firmware_version">
                <template #icon>
                    <cog-icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('firmwareVersion') }}:

                    <h3>{{ config.firmware_version }}</h3>
                </template>
            </measurement-item>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import CogIcon from './icons/CogIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'AssetConfigList',
    components: {
        CogIcon,
        MeasurementItem,
    },
    props: {
        config: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('authentication', ['isAdminOrStaff', 'isStaff']),
        interval() {
            let value = this.config.interval

            if (value) {
                value =
                    value >= 60
                        ? `${Math.floor(value / 60)} h ${value % 60} min`
                        : `${value} min`
            }

            return value
        },
    },
}
</script>

<i18n>
{
    "en": {
        "firmwareVersion": "Firmware Version",
        "gpsAccuracyGoal": "GPS Accuracy Goal",
        "gpsFixInterval": "GPS Fix Interval",
        "gpsFixTime": "GPS Fix Time",
        "interval": "Interval",
        "probeAddress": "Probe Address"
    },
    "de": {
        "firmwareVersion": "Firmware Version",
        "gpsAccuracyGoal": "GPS Genauigkeit",
        "gpsFixInterval": "GPS Fix Intervall",
        "gpsFixTime": "GPS Fix Zeit",
        "interval": "Messintervall",
        "probeAddress": "Sondenadresse"
    },
    "it": {
        "firmwareVersion": "Versione del firmware",
        "gpsAccuracyGoal": "Precisione GPS",
        "gpsFixInterval": "Intervallo di GPS",
        "gpsFixTime": "Tempo di GPS",
        "interval": "Intervallo",
        "probeAddress": "Indirizzo della sonda"
    }
}
</i18n>
