<template>
    <div>
        <measurement-item
            v-if="fullness"
            :title="fullness.title"
            :datetime="fullness.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'distance' },
                query: { assetType: 'bin' },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="fullness.level"
                />
            </template>

            <template #content>
                <div class="fullness-value">
                    {{ $t('fullness') }}

                    <h3 class="sensor-data-percentage">
                        {{ $t(fullness.level) }} - {{ fullness.value }}
                    </h3>
                </div>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperature.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import { measurementHelper } from '@/utils'

import BatteryIcon from './icons/BatteryIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'BinMeasurement',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        fullness() {
            const measurement = 'distance'
            const data = this.getMeasurementItem(measurement)

            if (!data) {
                return null
            }

            const value = measurementHelper.convertToBinLevel(
                this.tracker.asset_details.sensor_data[measurement].value
            )

            const [low, height] =
                this.tracker.asset_details.asset_type_identifier ===
                'sbb-bin-paper'
                    ? [20, 35]
                    : [40, 75]

            const level =
                value <= low ? 'low' : value >= height ? 'high' : 'medium'

            return { ...data, value, level }
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "fullness": "Fill value",
        "high": "Full",
        "lastMeasurement": "Last measurement",
        "low": "Empty",
        "medium": "Medium",
        "temperature": "Temperature"
    },
    "de": {
        "battery": "Batterie",
        "fullness": "Füllstand",
        "high": "Voll",
        "lastMeasurement": "Letzte Messung",
        "low": "Leer",
        "medium": "Mittel",
        "temperature": "Temperatur"
    },
    "it": {
        "battery": "Batteria",
        "fullness": "Livello di riempimento",
        "high": "Completo",
        "lastMeasurement": "Ultimo misurazione",
        "low": "Vuoto",
        "medium": "Mezzo",
        "temperature": "Temperatura"
    }
}
</i18n>

<style lang="scss" scoped>
.fullness-value {
    padding: 0;

    h3 {
        padding: 4px 0 2px;
        font-size: 20px;
    }
}

.low {
    color: $color-green;
}

.medium {
    color: $color-orange;
}

.high {
    color: $color-red;
}
</style>
