<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="cls-1" cx="12" cy="12" r="11.25"></circle>
        <path class="cls-1" d="M9,8.25a3,3,0,0,0-3,3v1.5a3,3,0,0,0,3,3" />
        <path
            class="cls-1"
            d="M12.75,8.25a1.5,1.5,0,0,0-1.5,1.5v4.5a1.5,1.5,0,0,0,3,0V9.75A1.5,1.5,0,0,0,12.75,8.25Z"
        />
        <path
            class="cls-1"
            d="M16.5,13.875a1.125,1.125,0,0,1,1.125-1.125h0a1.125,1.125,0,0,1,1.125,1.125h0a1.608,1.608,0,0,1-.351,1L16.5,17.25h2.25"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'CO2Icon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
