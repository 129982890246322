<template>
    <div>
        <measurement-item
            v-if="state"
            :title="state.title"
            :datetime="state.lastUpdated"
            :link-to="{
                name: 'operatingstatuschart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="state.value ? 'negative' : 'positive'"
                />
            </template>

            <template #content>
                <h3>{{ $t(state.value ? 'running' : 'notRunning') }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BatteryIcon from './icons/BatteryIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'AlertMeasurement',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        MeasurementItem,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        state() {
            return this.getMeasurementItem('d1')
        },
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "running": "Alarm",
        "notRunning": "OK",
        "lastMeasurement": "Last measurement"
    },
    "de": {
        "battery": "Batterie",
        "running": "Alarm",
        "notRunning": "OK",
        "lastMeasurement": "Letzte Messung"
    },
    "it": {
        "battery": "Batteria",
        "running": "Alarm",
        "notRunning": "OK",
        "lastMeasurement": "Ultimo misurazione"
    }
}
</i18n>

<style lang="scss" scoped>
h3 {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
