<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        viewbox-width="24"
        viewbox-height="24"
        @click="$emit('click')"
        ><rect
            class="a"
            x="-0.375"
            y="8.111"
            width="24.749"
            height="7.779"
            rx="1"
            ry="1"
            transform="translate(-4.971 12) rotate(-45.001)"
        ></rect>
        <line class="a" x1="4.015" y1="14.486" x2="6.515" y2="16.986"></line>
        <line class="a" x1="6.015" y1="12.486" x2="8.03" y2="14.47"></line>
        <line class="a" x1="8.257" y1="10.243" x2="10.757" y2="12.743"></line>
        <line class="a" x1="10.265" y1="8.236" x2="12.015" y2="9.986"></line>
        <line class="a" x1="12.5" y1="6" x2="15" y2="8.5"></line>
        <line class="a" x1="14.5" y1="4" x2="16.515" y2="5.986"></line>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'DistanceIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
}
</style>
