<template>
    <div>
        <measurement-item
            v-if="fullness"
            :title="fullness.title"
            :datetime="fullness.lastUpdated"
            :link-to="{
                name: 'sbbbinchart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <bin-fill-icon
                    width="24"
                    height="24"
                    :fullness="fullness.value"
                    :fill-color="colorsMap[fullness.level]"
                />
            </template>

            <template #content>
                {{ $t('fullness') }}:

                <h3 class="sensor-data-percentage">
                    {{ fullness.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="lastCollected"
            :title="lastCollected.title"
            :datetime="lastCollected.lastUpdated"
            :link-to="{
                name: 'sbbbinchart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <trash-bin-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('lastCollected') }}:

                <h3 v-if="lastCollected.lastUpdated">
                    <TimeAgo :from-datetime="lastCollected.lastUpdated" />
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: temperature.key },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import { measurementHelper } from '@/utils'

import BatteryIcon from '../../icons/BatteryIcon'
import BinFillIcon from '../../icons/BinFillIcon'
import MeasurementItem from '../../MeasurementItem'
import ThermometerIcon from '../../icons/ThermometerIcon'
import TimeAgo from '../../TimeAgo'
import TrashBinIcon from '../../icons/BinIcon'

export default {
    name: 'SbbBinMeasurement',
    components: {
        BatteryIcon,
        BinFillIcon,
        MeasurementItem,
        ThermometerIcon,
        TimeAgo,
        TrashBinIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            colorsMap: {
                low: '#41b883',
                medium: '#fdae61',
                high: '#d32f2f',
            },
        }
    },
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        fullness() {
            const measurement = 'distance'
            const data = this.getMeasurementItem(measurement)

            if (!data) {
                return null
            }

            const value = measurementHelper.convertToSbbBinLevel(
                this.tracker.asset_details.sensor_data[measurement].value
            )

            const [low, height] =
                this.tracker.asset_details.asset_type_identifier ===
                'sbb-bin-paper'
                    ? [20, 35]
                    : [40, 75]

            const level =
                value <= low ? 'low' : value >= height ? 'high' : 'medium'

            return { ...data, value, level }
        },
        lastCollected() {
            return this.getMeasurementItem('accel_y')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "battery": "Battery",
            "fullness": "Fill level",
            "lastCollected": "Last collection",
            "lastMeasurement": "Last measurement",
            "temperature": "Temperature"
        },
        "de": {
            "battery": "Batterie",
            "fullness": "Füllstand",
            "lastCollected": "Letzte Leerung",
            "lastMeasurement": "Letzte Messung",
            "temperature": "Temperatur"
        },
        "it": {
            "battery": "Batteria",
            "fullness": "Livello di riempimento",
            "lastCollected": "Ultimo svuotamento",
            "lastMeasurement": "Ultimo misurazione",
            "temperature": "Temperatura"
        }
    }
</i18n>

<style lang="scss" scoped>
h3 {
    p {
        font-size: unset;
    }
}

p {
    font-size: $fs-small;
}
</style>
