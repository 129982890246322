<template>
    <div>
        <measurement-item
            v-if="isMeasurementAvailable('distance')"
            :title="availability.title"
            :datetime="availability.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'distance' },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="availability.value ? 'green' : 'red'"
                />
            </template>

            <template #content>
                <h3 class="measurement-value">
                    {{ availability.value ? $t('free') : $t('occupied') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="isMeasurementAvailable('temperature')"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'temperature' },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="isMeasurementAvailable('battery')"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'battery' },
            }"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="
                !isMeasurementAvailable('battery') &&
                    isMeasurementAvailable('battery_voltage')
            "
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'battery_voltage' },
            }"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="100"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import { measurementHelper } from '@/utils'

import BatteryIcon from './icons/BatteryIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import ThermometerIcon from './icons/ThermometerIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'ParkingSpaceMeasurement',
    components: {
        BatteryIcon,
        FilledCircleIcon,
        ThermometerIcon,
        MeasurementItem,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        availability() {
            const measurement = 'distance'

            return {
                title: this.getItemTitle(measurement),
                value: measurementHelper.convertToParkingAvailability(
                    this.tracker.asset_details.sensor_data.distance.value
                ),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        battery() {
            const measurement = 'battery'

            return {
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        batteryVoltage() {
            const measurement = 'battery_voltage'

            return {
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        temperature() {
            const measurement = 'temperature'

            return {
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
    },
    methods: {
        isMeasurementAvailable(measurement) {
            return !!this.tracker.asset_details.sensor_data[measurement]
        },
        getItemTitle(measurement) {
            const lastUpdate = this.tracker.asset_details.sensor_data[
                measurement
            ].last_update

            return lastUpdate
                ? `${this.$t('lastMeasurement')}: ${moment(lastUpdate).format(
                      'DD.MM.YYYY HH:mm'
                  )}`
                : null
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "battery": "Battery",
            "free": "Free",
            "lastMeasurement": "Last measurement",
            "occupied": "Occupied",
            "temperature": "Temperature"
        },
        "de": {
            "battery": "Batterie",
            "free": "Frei",
            "lastMeasurement": "Letzte Messung",
            "occupied": "Besetzt",
            "temperature": "Temperatur"
        },
        "it": {
            "battery": "Batteria",
            "free": "Libero",
            "lastMeasurement": "Ultimo misurazione",
            "occupied": "Occupato",
            "temperature": "Temperatura"
        }
    }
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.measurement-value {
    padding-bottom: 2px;
    padding-left: 0;
    font-size: 20px;
}

.green {
    color: $color-green;
}

.red {
    color: $color-red;
}
</style>
