<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            d="M17.033,16.583l-4.492,4.686a.749.749,0,0,1-1.082,0L6.966,16.582"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M1.471,10.587a5.675,5.675,0,0,1-.122-5.3h0a5.673,5.673,0,0,1,9.085-1.474L12,5.374l1.566-1.566a5.673,5.673,0,0,1,9.085,1.474h0a5.68,5.68,0,0,1-.119,5.3"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M.75,13.583H7.268a.376.376,0,0,0,.336-.207L9.327,9.929a.376.376,0,0,1,.7.06l1.681,5.6a.376.376,0,0,0,.7.04l1.57-3.664a.376.376,0,0,1,.657-.061l1.116,1.674h7.5"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ActivityIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style scoped></style>
