<template>
    <div>
        <measurement-item
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'digitalinputchart',
                params: { id: tracker.id, dataType: 'd1' },
            }"
        >
            <template #icon>
                <electronics-switch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('d1') }}:

                <h3>
                    {{ $t(d1.value ? 'open' : 'closed') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="d2"
            :title="d2.title"
            :datetime="d2.lastUpdated"
            :link-to="{
                name: 'digitalinputchart',
                params: { id: tracker.id, dataType: 'd2' },
            }"
        >
            <template #icon>
                <electronics-switch-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('d2') }}:

                <h3>
                    {{ $t(d2.value ? 'open' : 'closed') }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import ElectronicsSwitchIcon from './icons/ElectronicsSwitchIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'DigitalInputMeasurement',
    components: {
        ElectronicsSwitchIcon,
        MeasurementItem,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        d1() {
            return this.getMeasurementItem('d1')
        },
        d2() {
            return this.getMeasurementItem('d2')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "closed": "Closed",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "lastMeasurement": "Last measurement",
        "open": "Open"
    },
    "de": {
        "closed": "Geschlossen",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "lastMeasurement": "Letzte Messung",
        "open": "Offen"
    },
    "it": {
        "closed": "Closed",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "lastMeasurement": "Ultimo misurazione",
        "open": "Open"
    }
}
</i18n>
