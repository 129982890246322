<template>
    <div>
        <component
            :is="customMeasurementComponent"
            v-if="customMeasurementComponent"
            :tracker="tracker"
        />

        <template v-else>
            <measurement-item
                v-if="isMeasurementExist('humidity')"
                :title="getItemTitle('humidity')"
                :datetime="humidity.lastUpdated"
                :link-to="
                    isMeasurementExist('temperature')
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                                  dataTypes: ['humidity', 'temperature'],
                              },
                          }
                        : {
                              name: 'charts',
                              params: {
                                  id: tracker.id,
                                  dataType: 'humidity',
                              },
                          }
                "
            >
                <template #icon>
                    <DropIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('humidity') }}:

                    <h3 class="sensor-data-percentage">
                        {{ humidity.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="isMeasurementExist('temperature')"
                :title="getItemTitle('temperature')"
                :datetime="temperature.lastUpdated"
                :link-to="
                    isMeasurementExist('humidity')
                        ? {
                              name: 'chartsCombined',
                              params: {
                                  id: tracker.id,
                                  dataTypes: ['humidity', 'temperature'],
                              },
                          }
                        : {
                              name: 'charts',
                              params: {
                                  id: tracker.id,
                                  dataType: 'temperature',
                              },
                          }
                "
            >
                <template #icon>
                    <ThermometerIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('temperature') }}:

                    <h3 class="sensor-data-temperature">
                        {{ temperature.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="isMeasurementExist('distance')"
                :title="getItemTitle('distance')"
                :datetime="distance.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: 'distance' },
                }"
            >
                <template #icon>
                    <DistanceIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('distance') }}:

                    <h3 class="sensor-data-distance">
                        {{ distance.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="isMeasurementExist('co2')"
                :title="getItemTitle('co2')"
                :datetime="co2.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: 'co2' },
                }"
            >
                <template #icon>
                    <CO2Icon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('co2') }}:

                    <h3 class="sensor-data-co2">
                        {{ co2.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="isMeasurementExist('voc')"
                :title="getItemTitle('voc')"
                :datetime="voc.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: 'voc' },
                }"
            >
                <template #icon>
                    <VocIcon width="24" height="24" />
                </template>

                <template #content>
                    {{ $t('voc') }}:

                    <h3 class="sensor-data-voc">
                        {{ voc.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="isMeasurementExist('battery')"
                :title="getItemTitle('battery')"
                :datetime="battery.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: 'battery' },
                }"
            >
                <template #icon>
                    <BatteryIcon
                        :battery-level-in-percent="battery.value"
                        :warn="false"
                        width="24"
                        height="24"
                    />
                </template>

                <template #content>
                    {{ $t('battery') }}:

                    <h3 class="sensor-data-percentage">
                        {{ battery.value }}
                    </h3>
                </template>
            </measurement-item>

            <measurement-item
                v-if="
                    !isMeasurementExist('battery') &&
                        isMeasurementExist('battery_voltage')
                "
                :title="getItemTitle('battery_voltage')"
                :datetime="batteryVoltage.lastUpdated"
                :link-to="{
                    name: 'charts',
                    params: { id: tracker.id, dataType: 'battery_voltage' },
                }"
            >
                <template #icon>
                    <BatteryIcon
                        :battery-level-in-percent="batteryVoltage.value"
                        :warn="false"
                        width="24"
                        height="24"
                    />
                </template>

                <template #content>
                    {{ $t('battery') }}:

                    <h3 class="sensor-data-voltage">
                        {{ batteryVoltage.value }}
                    </h3>
                </template>
            </measurement-item>
        </template>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import { formatHelper } from '@/utils'

import AlertMeasurement from './AlertMeasurement'
import AnimalMeasurement from './asset-types/animal/AnimalMeasurement'
import BatteryIcon from './icons/BatteryIcon'
import BinMeasurement from './BinMeasurement'
import CO2Icon from './icons/CO2Icon'
import DigitalInputMeasurement from './DigitalInputMeasurement'
import DistanceIcon from './icons/DistanceIcon'
import DropIcon from './icons/DropIcon'
import DynamicBinMeasurement from './asset-types/dynamic-bin/DynamicBinMeasurement'
import FlatRoofLightMeasurement from './FlatRoofLightMeasurement'
import FlatRoofMeasurement from './FlatRoofMeasurement'
import MachineMeasurement from './MachineMeasurement'
import MeasurementItem from './MeasurementItem'
import ParkingSpaceMeasurement from './ParkingSpaceMeasurement'
import SbbBinMeasurement from './asset-types/sbb-bin/SbbBinMeasurement'
import SiloMeasurement from './SiloMeasurement'
import ThermometerIcon from './icons/ThermometerIcon'
import VocIcon from './icons/VocIcon'

const customMeasurementComponents = {
    alert: AlertMeasurement,
    animal: AnimalMeasurement,
    bin: BinMeasurement,
    'digital-input': DigitalInputMeasurement,
    'dynamic-bin': DynamicBinMeasurement,
    'flat-roof': FlatRoofMeasurement,
    'flat-roof-light': FlatRoofLightMeasurement,
    'flat-roof-probe': FlatRoofMeasurement,
    machine: MachineMeasurement,
    'parking-space': ParkingSpaceMeasurement,
    'sbb-bin': SbbBinMeasurement,
    silo: SiloMeasurement,
}

export default {
    name: 'MeasurementsList',
    components: {
        BatteryIcon,
        CO2Icon,
        DistanceIcon,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
        VocIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        battery() {
            const measurement = 'battery'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        batteryVoltage() {
            const measurement = 'battery_voltage'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        co2() {
            const measurement = 'co2'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        customMeasurementComponent() {
            return customMeasurementComponents[
                this.tracker.asset_details.asset_type_type
            ]
        },
        distance() {
            const measurement = 'distance'

            return {
                value: this.getSensorValue(measurement, true),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        humidity() {
            const measurement = 'humidity'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        temperature() {
            const measurement = 'temperature'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        voc() {
            const measurement = 'voc'

            return {
                value: this.getSensorValue(measurement),
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
    },
    methods: {
        isMeasurementExist(measurement) {
            return !!this.tracker.asset_details.sensor_data[measurement]
        },
        getItemTitle(measurement) {
            const lastUpdate = this.tracker.asset_details.sensor_data[
                measurement
            ].last_update

            return lastUpdate
                ? `${this.$t('lastMeasurement')}: ${moment(lastUpdate).format(
                      'DD.MM.YYYY HH:mm'
                  )}`
                : null
        },
        getSensorValue(measurement, kFormatter = false) {
            const { value } = this.tracker.asset_details.sensor_data[
                measurement
            ]

            return kFormatter ? formatHelper.kFormatter(value) : value
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "battery": "Battery",
            "co2": "Carbon dioxide",
            "distance": "Distance",
            "humidity": "Humidity",
            "lastMeasurement": "Last measurement",
            "temperature": "Temperature",
            "voc": "VOC"
        },
        "de": {
            "battery": "Batterie",
            "co2": "Kohlenstoffdioxid",
            "distance": "Distanz",
            "humidity": "Luftfeuchtigkeit",
            "lastMeasurement": "Letzte Messung",
            "temperature": "Temperatur",
            "voc": "VOC"
        },
        "it": {
            "battery": "Batteria",
            "co2": "Anidride cabonica",
            "distance": "Distanza",
            "humidity": "Umidità",
            "lastMeasurement": "Ultimo misurazione",
            "temperature": "Temperatura",
            "voc": "VOC"
        }
    }
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
