<template>
    <div>
        <measurement-item
            v-if="hasMeasurement('fill_level')"
            :title="fill_level.title"
            :datetime="fill_level.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'fill_level' },
            }"
        >
            <template v-slot:icon>
                <bin-fill-icon
                    width="24"
                    height="24"
                    :fullness="fill_level.value * 100"
                    :fill-color="color_mapping[fill_level.level_category]"
                />
            </template>
            <template v-slot:content>
                {{ $t('fullness') }}:

                <h3 class="sensor-data-percentage">
                    {{ Math.round(fill_level.value * 100) }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="hasMeasurement('battery')"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: 'battery' },
            }"
        >
            <template v-slot:icon>
                <BatteryIcon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>
            <template v-slot:content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BatteryIcon from '../../icons/BatteryIcon'
import MeasurementItem from '../../MeasurementItem'
import BinFillIcon from '../../icons/BinFillIcon'
import { fillLevelThresholds } from './dynamicBinSettings'

export default {
    name: 'DynamicBinMeasurement',
    components: {
        BatteryIcon,
        MeasurementItem,
        BinFillIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            color_mapping: {
                low: '#41b883',
                medium: '#fdae61',
                high: '#d32f2f',
            },
        }
    },
    computed: {
        battery() {
            const measurement = 'battery'

            return {
                title: this.getItemTitle(measurement),
                value: this.tracker.asset_details.sensor_data[measurement]
                    .value,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
        fill_level() {
            const measurement = 'fill_level'

            const fill_level = this.tracker.asset_details.sensor_data[
                measurement
            ].value

            const level_category =
                fill_level <= fillLevelThresholds.low
                    ? 'low'
                    : fill_level >= fillLevelThresholds.high
                    ? 'high'
                    : 'medium'

            return {
                title: this.getItemTitle(measurement),
                value: fill_level,
                level_category: level_category,
                lastUpdated: this.tracker.asset_details.sensor_data[measurement]
                    .last_update,
            }
        },
    },
    methods: {
        hasMeasurement(measurement) {
            return !!this.tracker.asset_details.sensor_data[measurement]
        },
        getItemTitle(measurement) {
            const lastUpdate = this.tracker.asset_details.sensor_data[
                measurement
            ].last_update

            return lastUpdate
                ? `${this.$t('lastMeasurement')}: ${moment(lastUpdate).format(
                      'DD.MM.YYYY HH:mm'
                  )}`
                : null
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "battery": "Battery",
            "fullness": "Fill level",
            "lastMeasurement": "Last measurement"
        },
        "de": {
            "battery": "Batterie",
            "fullness": "Füllstand",
            "lastMeasurement": "Letzte Messung"
        },
        "it": {
            "battery": "Batteria",
            "fullness": "Livello di riempimento",
            "lastMeasurement": "Ultimo misurazione"
        }
    }
</i18n>
