<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        viewbox-width="24"
        viewbox-height="24"
        @click="$emit('click')"
    >
        <line class="a" x1="6.726" y1="0.75" x2="17.226" y2="0.75"></line>
        <path
            class="a"
            d="M15.726,8.25V.75h-7.5v7.5L1.489,18.615A3,3,0,0,0,4,23.25H19.948a3,3,0,0,0,2.515-4.635Z"
        />
        <line class="a" x1="5.301" y1="12.75" x2="18.651" y2="12.75"></line>
        <line class="a" x1="14.226" y1="17.25" x2="17.226" y2="17.25"></line>
        <line class="a" x1="15.726" y1="15.75" x2="15.726" y2="18.75"></line>
        <path class="a" d="M6.726,19.125A.375.375,0,0,1,7.1,19.5"></path>
        <path class="a" d="M6.351,19.5a.375.375,0,0,1,.375-.375"></path>
        <path class="a" d="M6.726,19.875a.375.375,0,0,1-.375-.375" />
        <path class="a" d="M7.1,19.5a.375.375,0,0,1-.375.375" /><path
            class="a"
            d="M9.726,16.125a.375.375,0,0,1,.375.375"
        /><path class="a" d="M9.351,16.5a.375.375,0,0,1,.375-.375" /><path
            class="a"
            d="M9.726,16.875a.375.375,0,0,1-.375-.375"
        /><path class="a" d="M10.1,16.5a.375.375,0,0,1-.375.375" /><line
            class="a"
            x1="15.726"
            y1="3.75"
            x2="12.726"
            y2="3.75"
        ></line
        ><line class="a" x1="15.726" y1="6.75" x2="12.726" y2="6.75"></line>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'VocIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
