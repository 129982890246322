var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showPicture && _vm.assetPictureUrl)?_c('div',{staticClass:"detail-image",style:({ backgroundImage: ("url(" + _vm.assetPictureUrl + ")") }),on:{"click":function($event){_vm.showImageInPopUp = true}}}):_vm._e(),_c('pop-over',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImageInPopUp),expression:"showImageInPopUp"}],staticStyle:{"z-index":"300"},on:{"exit":function($event){_vm.showImageInPopUp = false}}},[_c('img',{staticClass:"modal-asset-picture",attrs:{"src":_vm.assetPictureUrl}})]),_c('div',{staticClass:"l-inline l-gap-2 l-padded"},[_c('asset-avatar',{attrs:{"tracker":_vm.tracker,"size":64}}),_c('div',{staticClass:"l-stack l-gap-1 l-full-width"},[_c('div',{staticClass:"l-inline l-gap-1"},[_c('h3',[_vm._v(_vm._s(_vm.tracker.asset_details.name))]),(_vm.showEditButton && _vm.hasWritePermission)?_c('router-link',{attrs:{"to":("/map/assets/" + (_vm.tracker.id) + "/edit")}},[_c('IconButton',[_c('PencilIcon')],1)],1):_vm._e()],1),_c('div',{staticClass:"l-inline l-gap-1"},[(_vm.network_rssi && _vm.network_snr)?_c('div',[_c('signal-strength-indicator',{attrs:{"network-rssi":_vm.network_rssi,"network-snr":_vm.network_snr}})],1):_vm._e(),(_vm.batteryStatus)?_c('div',{staticClass:"l-inline l-center-v l-gap-1"},[_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.batteryStatus.value,"warn":_vm.batteryStatus.low}}),_c('p',[_vm._v(_vm._s(_vm.batteryStatus.value)+" %")])],1):_vm._e()]),(
                    _vm.tracker.asset_details &&
                        _vm.tracker.asset_details.identification
                )?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('identification'))+": "+_vm._s(_vm.tracker.asset_details.identification)+" ")])]):_vm._e(),(_vm.batteryStatus)?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastBatteryMeasurement'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.batteryStatus.last_update}})],1):_vm._e(),(
                    _vm.tracker.last_gps_measurement &&
                        _vm.tracker.last_gps_measurement.timestamp
                )?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastGPSMeasurement'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.tracker.last_gps_measurement.timestamp}}),_c('IconButton',{on:{"click":function($event){_vm.showDateInfoBox = !_vm.showDateInfoBox}}},[_c('InfoIcon',{attrs:{"width":13,"height":13}})],1)],1):_vm._e(),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.showDateInfoBox)?_c('InfoBox',[_vm._v(" "+_vm._s(_vm.$t('datetimeHelp'))+" ")]):_vm._e()],1),(
                    _vm.tracker.last_gps_measurement &&
                        _vm.tracker.last_gps_measurement.gps_accuracy != null
                )?_c('div',{staticClass:"detail-line"},[_c('p',{staticClass:"sensor-data-distance"},[_vm._v(" "+_vm._s(_vm.$t('gpsAccuracy'))+": "+_vm._s(_vm.tracker.last_gps_measurement.gps_accuracy)+" ")])]):_vm._e(),(_vm.tracker.last_message_timestamp)?_c('div',{staticClass:"detail-line"},[_c('p',[_vm._v(_vm._s(_vm.$t('lastContact'))+":")]),_c('TimeAgo',{attrs:{"from-datetime":_vm.tracker.last_message_timestamp}})],1):_vm._e(),(_vm.isAdminOrStaff)?_c('div',[_c('p',{staticClass:"t-underline t-subtle",staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showAdvancedConnectionInfo = !_vm.showAdvancedConnectionInfo}}},[_vm._v(" "+_vm._s(_vm.$t('showadvancedinformation'))+" ")])]):_vm._e(),_c('transition',{attrs:{"name":"dropdown"}},[(_vm.isAdminOrStaff)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdvancedConnectionInfo),expression:"showAdvancedConnectionInfo"}],staticClass:"l-stack l-gap-1"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.deveui),expression:"deveui"}],staticClass:"l-inline"},[_vm._v(" DevEUI: "+_vm._s(_vm.deveui)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.network_sf),expression:"network_sf"}],staticClass:"l-inline"},[_vm._v(" SF: "+_vm._s(_vm.network_sf)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.network_rssi),expression:"network_rssi"}],staticClass:"l-inline"},[_vm._v(" RSSI: "+_vm._s(_vm.network_rssi)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.network_snr),expression:"network_snr"}],staticClass:"l-inline"},[_vm._v(" SNR: "+_vm._s(_vm.network_snr)+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.network_name),expression:"network_name"}],staticClass:"l-inline"},[_vm._v(" "+_vm._s(_vm.$t('network'))+": "+_vm._s(_vm.network_name)+" ")])]):_vm._e()]),(
                    _vm.tracker.asset_details.tags &&
                        _vm.tracker.asset_details.tags.length
                )?_c('div',{staticClass:"l-inline tags"},_vm._l((_vm.tracker.asset_details.tags),function(tag,index){return _c('div',{key:index,class:tag.className},[_vm._v(" "+_vm._s(_vm.$t(tag.label))+" ")])}),0):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }