<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path class="a" d="M13.5,22a9.75,9.75,0,1,0-9.75-9.75V13"></path>

        <path class="a" d="M0.75 9.997L3.75 12.997 6.75 9.997"></path>

        <path class="a" d="M12.75 6.247L12.75 12.997 18 12.997"></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ClockIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
