<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="'none'"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M20.25,24C19.009,24,18,22.991,18,21.75v-15c0-1.241,1.009-2.25,2.25-2.25h1.5C22.991,4.5,24,5.509,24,6.75v15
		c0,1.241-1.009,2.25-2.25,2.25H20.25z M20.25,6c-0.414,0-0.75,0.336-0.75,0.75v15c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-15C22.5,6.336,22.164,6,21.75,6H20.25z"
        />
        <path
            d="M11.25,24C10.009,24,9,22.991,9,21.75v-9c0-1.241,1.009-2.25,2.25-2.25h1.5c1.241,0,2.25,1.009,2.25,2.25v9
		c0,1.241-1.009,2.25-2.25,2.25H11.25z M11.25,12c-0.414,0-0.75,0.336-0.75,0.75v9c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-9c0-0.414-0.336-0.75-0.75-0.75H11.25z"
        />
        <path
            d="M2.25,24C1.009,24,0,22.991,0,21.75v-3c0-1.241,1.009-2.25,2.25-2.25h1.5C4.991,16.5,6,17.509,6,18.75v3
		C6,22.991,4.991,24,3.75,24H2.25z M2.25,18c-0.414,0-0.75,0.336-0.75,0.75v3c0,0.414,0.336,0.75,0.75,0.75h1.5
		c0.414,0,0.75-0.336,0.75-0.75v-3C4.5,18.336,4.164,18,3.75,18H2.25z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SearchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
.b {
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
