<template>
    <signal-low-icon
        v-if="network_esp < -110"
        :name="$t('signallow')"
        width="24"
        height="24"
        :color="'#d32f2f'"
    />
    <signal-medium-icon
        v-else-if="network_esp < -100"
        :name="$t('signalmedium')"
        width="24"
        height="24"
        :color="'#fdae61'"
    />
    <signal-high-icon
        v-else
        :name="$t('signalhigh')"
        width="24"
        height="24"
        :color="'#41b883'"
    />
</template>

<script>
import SignalLowIcon from './icons/SignalLowIcon'
import SignalMediumIcon from './icons/SignalMediumIcon'
import SignalHighIcon from './icons/SignalHighIcon'

export default {
    components: {
        SignalLowIcon,
        SignalMediumIcon,
        SignalHighIcon,
    },
    props: {
        networkRssi: {
            type: Number,
            required: true,
        },
        networkSnr: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        network_esp() {
            if (this.networkRssi === null || this.networkRssi === undefined) {
                return null
            }
            if (this.networkSnr === null || this.networkSnr === undefined) {
                return null
            }
            return (
                this.networkRssi -
                10 * Math.log(1 + Math.pow(10, -this.networkSnr / 10))
            )
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "signallow": "low signal strength",
            "signalmedium": "medium signal strength",
            "signalhigh": "high signal strength"
        },
        "de": {
            "signallow": "niedrige Signalstärke",
            "signalmedium": "mittlere Signalstärke",
            "signalhigh": "hohe Signalstärke"
        },
        "it": {
            "signallow": "bassa potenza del segnale",
            "signalmedium": "intensità media del segnale",
            "signalhigh": "elevata potenza del segnale"
        }
    }
</i18n>
