var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{attrs:{"to":{
            name: 'animalactivitychart',
            params: {
                id: _vm.tracker.id,
            },
        }}},[_c('ListItem',{staticClass:"l-center"},[_c('div',{staticClass:"l-inline l-gap-2 l-center"},[_c('ActivityIcon',{attrs:{"width":"24","height":"24"}}),_c('div',{staticClass:"l-stack"},[_c('div',{staticClass:"l-inline l-center-v"},[_vm._v(" "+_vm._s(_vm.$t('showActivity'))+" ")])])],1),_c('p',[_c('BarChartIcon',{attrs:{"width":20,"height":20,"color":"lightgray"}})],1)])],1),(_vm.isMeasurementAvailable('battery'))?_c('measurement-item',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: 'battery' },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.battery.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,3831347632)}):_vm._e(),(
            !_vm.isMeasurementAvailable('battery') &&
                _vm.isMeasurementAvailable('battery_voltage')
        )?_c('measurement-item',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: 'battery_voltage' },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":100,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,3442578193)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }