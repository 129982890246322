<template>
    <div>
        <div v-if="!entry" class="l-padded">
            <p>{{ $t('assetDoesNotExist') }}</p>
        </div>

        <template v-else>
            <div class="u-bb">
                <AssetDetailOverview
                    :tracker="entryDetailed || entry"
                    :show-edit-button="true"
                />
            </div>

            <div
                v-if="
                    entry.location_details ||
                        (entry.asset_details &&
                            entry.asset_details.position.latitude &&
                            entry.asset_details.position.longitude)
                "
                class="u-bb"
            >
                <div class="l-padded u-bb">{{ $t('positionTitle') }}</div>

                <router-link
                    v-if="entry.location_details"
                    :to="{
                        name: 'location',
                        params: { id: entry.location_details.id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <pin-location-icon width="24" height="24" />
                            <div class="l-stack l-gap-1 l-center">
                                <h3>
                                    {{
                                        entry.location_details &&
                                            entry.location_details.name
                                    }}
                                </h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <a
                    v-if="showGoogleMapsLink"
                    :href="
                        'http://www.google.com/maps/place/' +
                            entry.asset_details.position.latitude +
                            ',' +
                            entry.asset_details.position.longitude
                    "
                    class="t-underline"
                    target="_blank"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center">
                            <open-in-new-icon width="24" height="24" />

                            <div class="l-stack l-gap-1">
                                <div>
                                    <a
                                        class="t-underline"
                                        :href="
                                            'http://www.google.com/maps/place/' +
                                                entry.asset_details.position
                                                    .latitude +
                                                ',' +
                                                entry.asset_details.position
                                                    .longitude
                                        "
                                        target="_blank"
                                    >
                                        {{ $t('showOnMap') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ListItem>
                </a>

                <router-link
                    v-if="
                        entry.last_gps_measurement &&
                            entry.last_gps_measurement.lat &&
                            entry.last_gps_measurement.lng
                    "
                    :to="{ name: 'locationhistory', params: { id } }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <clock-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>{{ $t('history') }}</h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <ListItem
                    v-if="!entry.asset_details.static"
                    class="l-inline l-spread l-center"
                >
                    <div>{{ $t('followTrackerUpdates') }}</div>

                    <toggle-button
                        :value="shouldFollowActiveTrackerUpdates"
                        :sync="true"
                        color="#02a5e1"
                        :width="40"
                        :height="24"
                        :speed="150"
                        @input="setShouldFollowActiveTrackerUpdates"
                    />
                </ListItem>
            </div>

            <div v-if="isMeasurementsAvailable" class="u-bb">
                <div class="l-padded u-bb">{{ $t('measurements') }}</div>

                <MeasurementsList :tracker="entry" />
            </div>

            <downlinks-list :id="id" class="u-bb" />

            <asset-notifications
                v-if="hasRulesAccess"
                :asset="entry.asset"
                class="u-bb"
            />

            <div v-if="config" class="u-bb">
                <div class="l-padded u-bb">{{ $t('configuration') }}</div>

                <asset-config-list :config="config" />
            </div>

            <asset-documents
                :asset="entry.asset"
                class="u-bb"
                :show-edit-actions="userHasEditRights"
            />

            <div v-if="isSuperuser" class="u-bb">
                <div class="l-padded u-bb">{{ $t('diagnostics') }}</div>

                <router-link
                    :to="{
                        name: 'assetConnectionHeatmap',
                        params: { assetId: id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <heatmap-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>{{ $t('connectionHeatmap') }}</h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>

                <router-link
                    :to="{
                        name: 'networkChart',
                        params: { assetId: id },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2">
                            <network-signal-icon width="24" height="24" />

                            <div class="l-stack l-gap-1 l-center">
                                <h3>{{ $t('signalQuality') }}</h3>
                            </div>
                        </div>

                        <p><i class="arrow right"></i></p>
                    </ListItem>
                </router-link>
            </div>
        </template>

        <transition name="slide-from-right">
            <div v-if="isChartViewActive" class="detached-panel">
                <div
                    class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                >
                    {{ detachedPanelTitle }}

                    <router-link to=".">
                        <icon-button @click="$emit('expand')">
                            <remove-icon width="24" height="24" color="black" />
                        </icon-button>
                    </router-link>
                </div>

                <div class="detached-panel-body">
                    <router-view />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { ToggleButton } from 'vue-js-toggle-button'

import { httpHelper } from '@/utils'

import AssetConfigList from '../AssetConfigList'
import AssetDetailOverview from '../AssetDetailOverview'
import AssetDocuments from '../AssetDocuments'
import AssetNotifications from '../AssetNotifications'
import ClockIcon from '../icons/HistoryClockIcon'
import DownlinksList from '../DownlinksList'
import HeatmapIcon from '../icons/HeatmapIcon'
import IconButton from '../IconButton'
import ListItem from '../ListItem'
import MeasurementsList from '../MeasurementsList'
import NetworkSignalIcon from '../icons/NetworkSignalIcon.vue'
import OpenInNewIcon from '../icons/OpenInNewIcon'
import PinLocationIcon from '../icons/PinLocationIcon'
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'AssetDetailView',
    components: {
        AssetConfigList,
        AssetDetailOverview,
        AssetDocuments,
        AssetNotifications,
        ClockIcon,
        DownlinksList,
        HeatmapIcon,
        IconButton,
        ListItem,
        MeasurementsList,
        NetworkSignalIcon,
        OpenInNewIcon,
        PinLocationIcon,
        RemoveIcon,
        ToggleButton,
    },
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            entryDetailed: null,
        }
    },
    computed: {
        ...mapState('tracker', [
            'shouldFollowActiveTrackerUpdates',
            'trackers',
        ]),
        ...mapGetters('authentication', ['hasRulesAccess', 'isSuperuser']),
        config() {
            return this.entryDetailed?.config
        },
        detachedPanelTitle() {
            if (
                this.$route.name === 'chartsCombined' &&
                this.$route.params.dataTypes?.length
            ) {
                return this.$route.params.dataTypes
                    .map(item => this.$t(`router.${item}`))
                    .join(' & ')
            }

            const routeName =
                this.$route.name === 'charts'
                    ? this.$route.params.dataType
                    : this.$route.name

            return this.$t(`router.${routeName}`)
        },
        entry() {
            return this.trackers.find(entry => entry.id == this.id)
        },
        userHasEditRights() {
            return this.entry?.asset_details?.permissions?.access === 'WRITE'
        },
        isChartViewActive() {
            return (
                this.$route.name === 'charts' ||
                this.$route.name === 'chartsCombined' ||
                this.$route.name === 'operatingstatuschart' ||
                this.$route.name === 'sbbbinchart' ||
                this.$route.name === 'animalactivitychart' ||
                this.$route.name === 'digitalinputchart' ||
                this.$route.name === 'networkChart'
            )
        },
        isMeasurementsAvailable() {
            return (
                this.entry?.asset_details?.sensor_data &&
                Object.keys(this.entry.asset_details.sensor_data).length
            )
        },
        showGoogleMapsLink() {
            return (
                this.entry.asset_details?.position?.latitude &&
                this.entry.asset_details?.position?.longitude &&
                process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet'
            )
        },
    },
    watch: {
        entry() {
            this.setActiveTrackerOnMap(this.entry)
        },
        id() {
            this.fetchAssetDetails()
        },
    },
    async mounted() {
        if (!this.entry) {
            return
        }

        this.setActiveTrackerOnMap(this.entry)
        this.setShouldFollowActiveTrackerUpdates(true)
        this.fetchAssetDetails()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name !== 'editAsset' && to.params.id !== this.entry?.asset) {
            this.setActiveTrackerOnMap(null)
        }

        next()
    },
    methods: {
        ...mapMutations('tracker', [
            'setActiveTrackerOnMap',
            'setShouldFollowActiveTrackerUpdates',
        ]),
        async fetchAssetDetails() {
            this.entryDetailed = null
            const { data } = await httpHelper.get(`trackers/${this.id}/`)
            this.entryDetailed = data
        },
    },
}
</script>

<i18n>
{
    "en": {
        "assetDoesNotExist": "This asset does not exist.",
        "configuration": "Configuration",
        "connectionHeatmap": "Connection Heatmap",
        "diagnostics": "Diagnostics",
        "documents": "Documents",
        "followTrackerUpdates": "Follow postion updates",
        "history": "History",
        "measurements": "Measurements",
        "notifications": "Notifications",
        "positionTitle": "Position information",
        "settings": "Settings",
        "showOnMap": "Show on Google Maps",
        "signalQuality": "Signal Quality",
        "tracking": "Tracking"
    },
    "de": {
        "assetDoesNotExist": "Dieses Asset existiert nicht.",
        "configuration": "Konfiguration",
        "connectionHeatmap": "Verbindungsqualität",
        "diagnostics": "Diagnose",
        "documents": "Dokumente",
        "followTrackerUpdates": "Live Position verfolgen",
        "history": "Verlauf",
        "measurements": "Messungen",
        "notifications": "Benachrichtigungen",
        "positionTitle": "Positionsinformationen",
        "settings": "Einstellungen",
        "showOnMap": "Auf Google Maps anzeigen",
        "signalQuality": "Signal Qualität",
        "tracking": "Tracking"
    },
    "it": {
        "assetDoesNotExist": "Questo asset non esiste.",
        "configuration": "Configurazione",
        "connectionHeatmap": "Qualità della connessione",
        "diagnostics": "Diagnostica",
        "documents": "Documenti",
        "followTrackerUpdates": "Seguire la posizione",
        "history": "Storico",
        "measurements": "Misure",
        "notifications": "Notificazione",
        "positionTitle": "Informazioni sulla posizione",
        "settings": "Impostazione",
        "showOnMap": "Vedere su Google Maps",
        "signalQuality": "Qualità del segnale",
        "tracking": "Tracking"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}

.list-item__action {
    cursor: pointer;
}

.last_gps_measurement-icon {
    background-color: orange;
}
</style>
