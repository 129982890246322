<template>
    <transition name="fade">
        <div
            ref="popover"
            class="popover_container l-inline l-center-v l-center"
            @click.prevent="$emit('exit')"
        >
            <div class="popover_content" @click.stop>
                <div class="popover_close" @click="$emit('exit')">
                    <remove-icon width="18" height="18" />
                </div>

                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
import RemoveIcon from './icons/RemoveIcon'

export default {
    name: 'PopOver',
    components: {
        RemoveIcon,
    },
    mounted() {
        // Adding DOM element as direct child of body. This to escape other "context blocks" inhibiting
        // element to "pop over" with z-index.
        let elx = this.$refs.popover
        document.body.insertBefore(elx, document.body.firstChild)
    },
    beforeDestroy() {
        let elx = this.$refs.popover
        if (document.body) {
            document.body.removeChild(elx)
        }
    },
}
</script>

<style lang="scss" scoped>
.popover {
    &_container {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        background-color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
    }

    &_content {
        position: relative;
        cursor: default;
    }

    &_close {
        position: absolute;
        top: -12px;
        right: -12px;
        padding: 7px;
        width: 32px;
        height: 32px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
        transition: background 0.1s;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-lighter-new;

            svg {
                opacity: 1;
            }
        }

        svg {
            opacity: 0.5;
            transition: opacity 0.1s;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
