<template>
    <div class="information-box"><slot></slot></div>
</template>

<script>
export default {
    name: 'InfoBox',
}
</script>

<style scoped lang="scss">
.information-box {
    background: $color-gray-lighter;
    padding: 0.5rem;
    margin-bottom: 1rem;
    line-height: 1.6;
    font-size: $fs-label;
}
</style>
