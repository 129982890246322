<template>
    <ListItem class="l-inline l-spread l-center">
        <div>{{ $t(name) }}</div>
        <toggle-button
            :value="output"
            :sync="true"
            color="#02a5e1"
            :width="40"
            :height="24"
            :speed="150"
            @change="doSwitch"
        />
    </ListItem>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'
import ListItem from './ListItem'

export default {
    name: 'DownlinkSwitch',
    components: {
        ListItem,
        ToggleButton,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        output: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        doSwitch(event) {
            this.$emit('switched-downlink', this.name, event.value)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "OUTPUT_1": "Channel 1",
        "OUTPUT_2": "Channel 2"
    },
    "de": {
        "OUTPUT_1": "Kanal 1",
        "OUTPUT_2": "Kanal 2"
    },
    "it": {
        "OUTPUT_1": "Canale 1",
        "OUTPUT_2": "Canale 2"
    }
}
</i18n>
