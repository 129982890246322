var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.customMeasurementComponent)?_c(_vm.customMeasurementComponent,{tag:"component",attrs:{"tracker":_vm.tracker}}):[(_vm.isMeasurementExist('humidity'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('humidity'),"datetime":_vm.humidity.lastUpdated,"link-to":_vm.isMeasurementExist('temperature')
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: _vm.tracker.id,
                              dataTypes: ['humidity', 'temperature'],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: _vm.tracker.id,
                              dataType: 'humidity',
                          },
                      }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,1596388769)}):_vm._e(),(_vm.isMeasurementExist('temperature'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('temperature'),"datetime":_vm.temperature.lastUpdated,"link-to":_vm.isMeasurementExist('humidity')
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: _vm.tracker.id,
                              dataTypes: ['humidity', 'temperature'],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: _vm.tracker.id,
                              dataType: 'temperature',
                          },
                      }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),(_vm.isMeasurementExist('distance'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('distance'),"datetime":_vm.distance.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: 'distance' },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DistanceIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('distance'))+": "),_c('h3',{staticClass:"sensor-data-distance"},[_vm._v(" "+_vm._s(_vm.distance.value)+" ")])]},proxy:true}],null,false,1223689488)}):_vm._e(),(_vm.isMeasurementExist('co2'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('co2'),"datetime":_vm.co2.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: 'co2' },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CO2Icon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('co2'))+": "),_c('h3',{staticClass:"sensor-data-co2"},[_vm._v(" "+_vm._s(_vm.co2.value)+" ")])]},proxy:true}],null,false,4293527888)}):_vm._e(),(_vm.isMeasurementExist('voc'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('voc'),"datetime":_vm.voc.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: 'voc' },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('VocIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('voc'))+": "),_c('h3',{staticClass:"sensor-data-voc"},[_vm._v(" "+_vm._s(_vm.voc.value)+" ")])]},proxy:true}],null,false,3326245232)}):_vm._e(),(_vm.isMeasurementExist('battery'))?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('battery'),"datetime":_vm.battery.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: 'battery' },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.battery.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,3831347632)}):_vm._e(),(
                !_vm.isMeasurementExist('battery') &&
                    _vm.isMeasurementExist('battery_voltage')
            )?_c('measurement-item',{attrs:{"title":_vm.getItemTitle('battery_voltage'),"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
                name: 'charts',
                params: { id: _vm.tracker.id, dataType: 'battery_voltage' },
            }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.batteryVoltage.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,3866045258)}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }