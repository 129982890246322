<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M17.249 14.999L19.499 14.999"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M15.749 10.499L19.499 10.499"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M14.249 19.499L19.499 19.499"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M4.499 14.249 A4.500 4.500 0 1 0 13.499 14.249 A4.500 4.500 0 1 0 4.499 14.249 Z"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M5.294 16.718L8.999 14.249 8.999 9.749"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M22.809,5.559a1.5,1.5,0,0,1,.44,1.061V21A2.251,2.251,0,0,1,21,23.249H3A2.25,2.25,0,0,1,.749,21V3A2.25,2.25,0,0,1,3,.749H17.377a1.5,1.5,0,0,1,1.061.439Z"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path
        ><path
            d="M17.249.749v4.5a1.5,1.5,0,0,0,1.5,1.5h4.5"
            fill="none"
            stroke="#000000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
        ></path>
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'FileIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style scoped></style>
