var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fullness)?_c('measurement-item',{attrs:{"title":_vm.fullness.title,"datetime":_vm.fullness.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: 'distance' },
            query: { assetType: 'bin' },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.fullness.level,attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"fullness-value"},[_vm._v(" "+_vm._s(_vm.$t('fullness'))+" "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.$t(_vm.fullness.level))+" - "+_vm._s(_vm.fullness.value)+" ")])])]},proxy:true}],null,false,3106912947)}):_vm._e(),(_vm.temperature)?_c('measurement-item',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.temperature.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),(_vm.battery)?_c('measurement-item',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.battery.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.battery.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,2839283537)}):_vm._e(),(!_vm.battery && _vm.batteryVoltage)?_c('measurement-item',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: { id: _vm.tracker.id, dataType: _vm.batteryVoltage.key },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,2155094608)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }