<template>
    <div>
        <measurement-item
            v-if="waterLevel"
            :title="waterLevel.title"
            :datetime="waterLevel.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: waterLevel.key },
            }"
        >
            <template #icon>
                <bar-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('waterLevel') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ waterLevel.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="potential"
            :title="potential.title"
            :datetime="potential.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: potential.key },
            }"
        >
            <template #icon>
                <line-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('potential') }}:

                <h3>{{ potential.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="isAdminOrStaff && tau"
            :title="tau.title"
            :datetime="tau.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: tau.key },
            }"
        >
            <template #icon>
                <line-chart-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('tau') }}:

                <h3>{{ tau.value }}</h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: humidity.key,
                          },
                      }
            "
        >
            <template #icon>
                <drop-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                              dataTypes: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: temperature.key,
                          },
                      }
            "
        >
            <template #icon>
                <thermometer-icon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: battery.key },
            }"
        >
            <template #icon>
                <battery-icon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="!battery && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: batteryVoltage.key },
            }"
        >
            <template #icon>
                <battery-icon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="probeState"
            :title="probeState.title"
            :datetime="probeState.lastUpdated"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="probeState.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('probeState') }}:

                <h3>
                    {{ $t(probeState.value ? 'statusOk' : 'statusNotOk') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="isAdminOrStaff && upperLoop"
            :title="upperLoop.title"
            :datetime="upperLoop.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: upperLoop.key },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="upperLoop.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('upperLoop') }}:

                <h3>
                    {{ $t(upperLoop.value ? 'loopPositive' : 'loopNegative') }}
                </h3>
            </template>
        </measurement-item>

        <measurement-item
            v-if="isAdminOrStaff && lowerLoop"
            :title="lowerLoop.title"
            :datetime="lowerLoop.lastUpdated"
            :link-to="{
                name: 'charts',
                params: { id: tracker.id, dataType: lowerLoop.key },
            }"
        >
            <template #icon>
                <filled-circle-icon
                    width="24"
                    height="24"
                    :class="lowerLoop.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('lowerLoop') }}:

                <h3>
                    {{ $t(lowerLoop.value ? 'loopPositive' : 'loopNegative') }}
                </h3>
            </template>
        </measurement-item>
    </div>
</template>

<script>
import moment from 'moment-timezone'

import BarChartIcon from './icons/BarChartIcon'
import BatteryIcon from './icons/BatteryIcon'
import DropIcon from './icons/DropIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import ThermometerIcon from './icons/ThermometerIcon'
import { mapGetters } from 'vuex'

export default {
    name: 'FlatRoofLightMeasurement',
    components: {
        BarChartIcon,
        BatteryIcon,
        DropIcon,
        FilledCircleIcon,
        LineChartIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    props: {
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters('authentication', ['isAdminOrStaff']),
        battery() {
            return this.getMeasurementItem('battery')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        lowerLoop() {
            return this.getMeasurementItem('lower_loop')
        },
        potential() {
            return this.getMeasurementItem('potential')
        },
        tau() {
            return this.getMeasurementItem('tau')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        probeState() {
            return this.getMeasurementItem('probe_state')
        },
        upperLoop() {
            return this.getMeasurementItem('upper_loop')
        },
        waterLevel() {
            return this.getMeasurementItem('water_level')
        },
    },
    methods: {
        getItemTitle(lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            return `${this.$t('lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.tracker.asset_details.sensor_data[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title: data.last_update && this.getItemTitle(data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "battery": "Battery",
        "humidity": "Humidity",
        "lastMeasurement": "Last measurement",
        "probeState": "Probe state",
        "statusOk": "OK",
        "statusNotOk": "No answer",
        "loopNegative": "Open",
        "loopPositive": "OK",
        "lowerLoop": "Lower loop",
        "potential": "Potential",
        "temperature": "Temperature",
        "upperLoop": "Upper loop",
        "waterLevel": "Water level",
        "tau": "Tau"
    },
    "de": {
        "battery": "Batterie",
        "humidity": "Luftfeuchtigkeit",
        "lastMeasurement": "Letzte Messung",
        "probeState": "Sondenstatus",
        "statusOk": "OK",
        "statusNotOk": "Keine Antwort",
        "loopNegative": "Offen",
        "loopPositive": "OK",
        "lowerLoop": "Untere Schlaufe",
        "potential": "Potential",
        "temperature": "Temperatur",
        "upperLoop": "Obere Schlaufe",
        "waterLevel": "Wasserpegel",
        "tau": "Tau"
    },
    "it": {
        "battery": "Batteria",
        "humidity": "Humidity",
        "lastMeasurement": "Ultimo misurazione",
        "probeState": "Stato campione",
        "statusOk": "OK",
        "statusNotOk": "Nessuna risposta",
        "loopNegative": "Aperto",
        "loopPositive": "OK",
        "lowerLoop": "Loop inferiore",
        "potential": "Potentiale",
        "temperature": "Temperatura",
        "upperLoop": "Loop superiore",
        "waterLevel": "Livello dell'acqua",
        "tau": "Tau"
    }
}
</i18n>

<style lang="scss" scoped>
.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
